import React, { useContext, useEffect, useState } from 'react'
import { CardPost } from '~/components'
import * as st from '~/assets/styl/ContainerPosts.module.styl'
import imagePlaceholder from '~/assets/img/blog-mock.jpg'
import { context } from '~/layouts'
import cn from 'classnames'
import { useApiBlog as api } from '~/siteApi'

interface ContainerPostsProps {
  posts: Posts[]
  searchOrFilter?: boolean
  categoriesName: (id: number) => string
  totalPages?: number
}

const ContainerPosts = ({
  totalPages,
  posts,
  searchOrFilter,
  categoriesName,
}: ContainerPostsProps) => {
  const { currentPage, setCurrentPage } = useContext(context)
  const handlePageClick = (page) => {
    setCurrentPage(page)
  }

  const handlePrevClick = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1)
    }
  }

  const handleNextClick = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1)
    }
  }
  const [media, setMedia] = useState<{ [key: number]: string }>({})

  const fetchMedia = async () => {
    try {
      const mediaRequests = await Promise.all(
        posts.map(async (post) => {
          if (post.featured_media) {
            const response = await api.requestData(
              'get',
              `media/${post.featured_media}`
            )
            return {
              id: post.id,
              url:
                response.data.media_details.sizes['list-thumbnail']
                  ?.source_url || null,
            }
          }
          return { id: post.id, url: null }
        })
      )

      const mediaData = mediaRequests.reduce((acc, { id, url }) => {
        acc[id] = url
        return acc
      }, {})

      setMedia(mediaData)
    } catch (error) {
      console.error('Erro ao buscar as mídias dos posts:', error)
    }
  }
  useEffect(() => {
    fetchMedia()
  }, [posts])
  return (
    <>
      <div className={searchOrFilter ? st.coreSearchOrFilter : st.core}>
        {posts.map(({ categories, date, id, slug, title }) => (
          <>
            <CardPost
              slug={slug}
              key={id}
              date_gmt={date}
              image={media[id] != null ? media[id] : imagePlaceholder}
              categories={
                categories?.map((catId) => categoriesName(catId)) ?? []
              }
              title={title}
            />
          </>
        ))}
      </div>
      {totalPages > 1 && (
        <div className={st.pagination}>
          <div className={st.wrapper}>
            <button
              className={cn(st.arrow, st.prev)}
              onClick={handlePrevClick}
              disabled={currentPage === 1}
            ></button>
            <div className={st.numbers}>
              {Array.from({ length: totalPages }, (_, i) => i + 1).map(
                (page) => (
                  <button
                    key={page}
                    onClick={() => handlePageClick(page)}
                    disabled={page === currentPage}
                  >
                    {page}
                  </button>
                )
              )}
            </div>
            <button
              className={cn(st.arrow, st.next)}
              onClick={handleNextClick}
              disabled={currentPage == totalPages}
            ></button>
          </div>
        </div>
      )}
    </>
  )
}

export default ContainerPosts
