import React from 'react'
import * as st from '~/assets/styl/PaginationBlog.module.styl'
import cn from 'classnames'

interface PaginationBlogProps {
  page: number
  setPage: (page: number) => void
  totalPages: number
}

const PaginationBlog = ({ page, setPage, totalPages }: PaginationBlogProps) => {
  const pages = Array.from({ length: totalPages }, (_, index) => index + 1)

  const renderPageNumbers = () => {
    const range = 6
    let start = Math.max(page - Math.floor(range / 2), 1)
    let end = start + range - 1

    if (end > totalPages) {
      end = totalPages
      start = Math.max(end - range + 1, 1)
    }

    return pages.slice(start - 1, end).map((p) => (
      <button
        key={p}
        className={cn(st.pageNumber, { [st.active]: p === page })}
        onClick={() => setPage(p)}
        aria-current={p === page ? 'page' : undefined}
      >
        {p}
      </button>
    ))
  }

  return (
    <div className={st.core}>
      <button
        className={cn(st.prev, st.arrows)}
        onClick={() => setPage(page - 1)}
        disabled={page === 1}
      >
        Prev
      </button>
      {renderPageNumbers()}
      <button
        className={cn(st.next, st.arrows)}
        onClick={() => setPage(page + 1)}
        disabled={page === totalPages}
      >
        Next
      </button>
    </div>
  )
}

export default PaginationBlog
