// extracted by mini-css-extract-plugin
export var address = "Footer-module--address--13f6a";
export var certs = "Footer-module--certs--64370";
export var container = "Footer-module--container--d66d8";
export var copyright = "Footer-module--copyright--a4e0b";
export var core = "Footer-module--core--340e5";
export var info = "Footer-module--info--0130b";
export var logo = "Footer-module--logo--63ba7";
export var nav = "Footer-module--nav--7d35c";
export var outlined = "Footer-module--outlined--df4f7";
export var socials = "Footer-module--socials--a5672";
export var tiki = "Footer-module--tiki--bc2cd";