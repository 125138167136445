import React from 'react'
import * as st from '~/assets/styl/CardPost.module.styl'
import Link from './Link'
import { formatDate } from '~/utils/formatDate'
import cn from 'classnames'

interface CardProps {
  image: string
  categories: (string | undefined)[]
  date_gmt: string
  title: {
    rendered: string
  }
  slug: string
  related?: boolean
}

const CardPost = ({
  image,
  categories,
  date_gmt,
  slug,
  title,
  related,
}: CardProps) => {
  return (
    <div className={cn(st.core, { [st.related]: !related })}>
      <figure>
        <img src={image} />
      </figure>
      <div className={st.dateAndTags}>
        {categories && categories.length > 0 && (
          <div className={st.tags}>
            {categories.map((categorie, index) => (
              <span key={index}>{categorie}</span>
            ))}
          </div>
        )}
        <div className={st.date}>
          <span>{formatDate(date_gmt)}</span>
        </div>
      </div>
      <div className={st.title}>
        <span>{title.rendered}</span>
      </div>
      <Link href={`/blog/${slug}`}></Link>
    </div>
  )
}

export default CardPost
