import React, { useState, ChangeEvent } from 'react'
import { Button, Link } from './'
import { serialize } from 'object-to-formdata'
import { useCookies } from 'react-cookie'
import { navigate } from 'gatsby'
import { rdStationApi } from '~/siteApi'
import * as st from '~/assets/styl/Newsletter.module.styl'

interface NewsletterProps {
  categorieOrSearch?: boolean
}

const Newsletter = ({ categorieOrSearch }: NewsletterProps) => {
  const [data, setData] = useState({ nome: '', email: '', privacy: false })
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState({ nome: '', email: '', privacy: '' })
  const [cookies] = useCookies()

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setData({
      ...data,
      [e.target.name]:
        e.target.type === 'checkbox' ? e.target.checked : e.target.value,
    })
  }

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    setLoading(true)
    document.body.style.cursor = 'wait'

    const callApi = rdStationApi
    const token_rdstation = 'bb08b424c77658fcabcc22be988acf83'

    callApi(
      'post',
      'conversions',
      serialize({
        ...data,
        utmz: cookies?.['__trf.src'] || cookies?.__utmzz,
        token_rdstation,
        legal_bases: [
          {
            category: 'communications',
            type: 'consent',
            status: 'granted',
          },
          {
            category: 'data_processing',
            type: 'consent',
            status: 'granted',
          },
        ],
        identificador: 'Aikon Blog Newsletter',
      })
    )
      .then(() => {
        document.body.style.cursor = null
        navigate('/sucesso/')
      })
      .catch((e) => {
        if (e.errors) setError(e.errors)
        else console.error(e)
        setLoading(false)
        document.body.style.cursor = null
      })
  }

  return (
    <div
      style={
        categorieOrSearch ? { marginLeft: '20px', marginRight: '20px' } : {}
      }
      className={st.core}
    >
      <div className={st.content}>
        <section className={st.text}>
          <h2>Assine nossa newsletter</h2>
          <p>
            Receba nossos conteúdos diretamente em seu email e fique atualizado
            com as novidades da Aikon Empreendimentos.
          </p>
        </section>
        <form className={st.containerForm} onSubmit={onSubmit}>
          <div className={st.field}>
            <label htmlFor="nome">Nome*</label>
            <input
              type="text"
              id="nome"
              name="nome"
              value={data.nome}
              onChange={onChange}
              required
            />
          </div>
          <div className={st.field}>
            <label htmlFor="email">E-mail*</label>
            <input
              type="email"
              id="email"
              name="email"
              value={data.email}
              onChange={onChange}
              required
            />
          </div>
          <div className={st.privacy}>
            <label>
              <input
                type="checkbox"
                id="privacy"
                name="privacy"
                checked={data.privacy}
                onChange={onChange}
                required
              />
              Declaro que li e aceito a{' '}
              <Link href="/Política de Privacidade">
                Política de Privacidade
              </Link>
            </label>
          </div>
          <Button className={st.toSign}>
            {loading ? 'Enviando...' : 'Assinar'}
          </Button>
        </form>
      </div>
    </div>
  )
}

export default Newsletter
