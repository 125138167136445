import React from 'react'
import Slider from '@fil1pe/react-slider'
import CardPost from './CardPost'
import * as st from '~/assets/styl/RelatedPosts.module.styl'
import { useMediaQuery } from 'react-responsive'

interface RelatedPostsProps {
  posts: Post[]
  categoriesName: (id: number) => string
}

const RelatedPosts = ({ posts, categoriesName }: RelatedPostsProps) => {
  const desktop = useMediaQuery({
    query: '(min-width: 1024px)',
  })
  return (
    <section className={st.core}>
      <h2>Posts Relacionados</h2>
      <Slider finite className={st.slider} slidesToShow={!desktop ? 1 : 3}>
        {posts.map(
          ({ date, categories, title, yoast_head_json, slug }, index) => (
            <CardPost
              related
              key={index}
              date_gmt={date}
              image={yoast_head_json.og_image[0].url}
              categories={categories.map((categoryId) =>
                categoriesName(categoryId)
              )}
              slug={slug}
              title={title}
            />
          )
        )}
      </Slider>
    </section>
  )
}

export default RelatedPosts
