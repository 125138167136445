import axios from 'axios'
const main = axios.create()

main.requestData = (method, path, data, config = {}) => {
  const apiUrl = `https://blog.aikonempreendimentos.com.br/wp-json/wp/v2/${path}`

  const promise = main[method](apiUrl, data, config)
    .then((response) => {
      return response
    })
    .catch((error) => {
      if (error.response && error.response.data) {
        return error.response.data
      }
      return { errors: {} }
    })

  return promise
}

export default main
