export default [
  {
    name: 'Home',
    url: '/',
  },
  {
    name: 'Empreendimentos',
    url: '/empreendimentos/',
    isEnterprises: true,
    subItems: [
      {
        name: 'Balneário Camboriú',
        url: '/empreendimentos/?search=balneario-camboriu',
      },
      { name: 'Praia Brava', url: '/empreendimentos/?search=praia-brava' },
      { name: 'Itajaí', url: '/empreendimentos/?search=itajai' },
      { name: 'Ver todos', url: '/empreendimentos/' },
    ],
  },
  {
    name: 'Aikon',
    url: '/sobre/',
    subItems: [
      { name: 'Sobre', url: '/sobre/' },
      { name: 'Como fazemos', url: '/sobre/#ComoFazemos' },
      { name: 'Modelos de negócio', url: '/sobre/#ModeloDeNegocio' },
    ],
  },
  {
    name: 'Blog',
    url: '/blog/',
  },
  {
    name: 'Contato',
    url: '/contato/',
    subItems: [
      { name: 'Fale conosco', url: '/contato/#FaleConosco' },
      { name: 'Trabalhe conosco', url: '/contato/#TrabalheConosco' },
      { name: 'Seja Um Parceiro Aikon', url: '/contato/#ParceiroAikon' },
    ],
  },
  {
    name: 'Certificações',
    url: '/certificacoes/',
  },
  {
    name: 'Área do cliente',
    url: 'https://aikon.cvcrm.com.br/cliente/',
    external: true,
    outlined: true,
  },
  {
    name: 'Área do corretor',
    url: '/corretor/',
    outlined: true,
  },
]
