import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useEffect,
  useState,
} from 'react'
import { TransitionProvider, TransitionViews } from 'gatsby-plugin-transitions'
import { PageProps } from 'gatsby'
import { Loading, Nav } from '~/components'

interface Context {
  hamburgerOpen: boolean
  setHamburgerOpen: Dispatch<SetStateAction<boolean>>
  setLoading: (loading: boolean) => void
  currentPage: number
  setCurrentPage: Dispatch<SetStateAction<number>>
}

export const context = createContext<Context>(null)

const layout = (loadingDefault: boolean, { children, location }: PageProps) => {
  const [hamburgerOpen, setHamburgerOpen] = useState(false)
  const [loading, setLoading] = useState(loadingDefault)
  const [loadingVisible, setLoadingVisible] = useState(loadingDefault)
  const [currentPage, setCurrentPage] = useState(1)

  const [enterOpacity, setEnterOpacity] = useState(0.9999)
  useEffect(() => {
    setCurrentPage(1)
    setEnterOpacity(0)
  }, [])

  return (
    <>
      {loading && <Loading visible={loadingVisible} />}
      <Nav
        location={location}
        hamburgerOpen={hamburgerOpen}
        setHamburgerOpen={setHamburgerOpen}
      />
      <context.Provider
        value={{
          hamburgerOpen,
          setHamburgerOpen,
          setLoading: (loading: boolean) => {
            if (!loading) {
              setLoadingVisible(false)
              setTimeout(() => setLoading(false), 300)
            } else {
              setLoadingVisible(true)
              setLoading(true)
            }
          },
          currentPage,
          setCurrentPage,
        }}
      >
        <TransitionProvider
          location={location}
          mode="immediate"
          enter={{
            opacity: enterOpacity,
          }}
          usual={{
            opacity: 1,
          }}
          leave={{
            opacity: 0,
            config: {
              duration: 200,
            },
          }}
        >
          <TransitionViews>{children}</TransitionViews>
        </TransitionProvider>
      </context.Provider>
    </>
  )
}

export default (props: PageProps) => {
  const context = props.pageContext as { [key: string]: never }
  if (context?.layout === '404') return layout(true, props)
  return layout(false, props)
}
