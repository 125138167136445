// extracted by mini-css-extract-plugin
export var FaleConosco = "Contact-module--FaleConosco--4d7bb";
export var ParceiroAikon = "Contact-module--ParceiroAikon--174a6";
export var TrabalheConosco = "Contact-module--TrabalheConosco--a4fbc";
export var container = "Contact-module--container--8b26f";
export var core = "Contact-module--core--dd369";
export var forms = "Contact-module--forms--edc04";
export var head = "Contact-module--head--6dd87";
export var image = "Contact-module--image--fb0c1";
export var privacy = "Contact-module--privacy--9ef21";
export var search = "Contact-module--search--ccddf";
export var tab = "Contact-module--tab--eb407";
export var text = "Contact-module--text--93122";
export var whatsapp = "Contact-module--whatsapp--b0a53";
export var wide = "Contact-module--wide--6e1d8";