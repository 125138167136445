// extracted by mini-css-extract-plugin
export var business = "Team-module--business--8d7c4";
export var certs = "Team-module--certs--5b329";
export var close = "Team-module--close--5edda";
export var container = "Team-module--container--062e9";
export var content = "Team-module--content--14113";
export var core = "Team-module--core--50617";
export var director = "Team-module--director--352d7";
export var image = "Team-module--image--75ae0";
export var iso = "Team-module--iso--0b4ed";
export var mate = "Team-module--mate--87249";
export var mates = "Team-module--mates--94efc";
export var modal = "Team-module--modal--5a993";
export var number = "Team-module--number--f78e6";
export var policy = "Team-module--policy--95ca1";
export var spiral1 = "Team-module--spiral1--77e50";
export var spiral2 = "Team-module--spiral2--c27cd";
export var team = "Team-module--team--ab253";