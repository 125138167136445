exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-[slug]-tsx": () => import("./../../../src/pages/blog/[slug].tsx" /* webpackChunkName: "component---src-pages-blog-[slug]-tsx" */),
  "component---src-pages-blog-autor-[slug]-tsx": () => import("./../../../src/pages/blog/autor/[slug].tsx" /* webpackChunkName: "component---src-pages-blog-autor-[slug]-tsx" */),
  "component---src-pages-blog-busca-[slug]-tsx": () => import("./../../../src/pages/blog/busca/[slug].tsx" /* webpackChunkName: "component---src-pages-blog-busca-[slug]-tsx" */),
  "component---src-pages-blog-categoria-[slug]-tsx": () => import("./../../../src/pages/blog/categoria/[slug].tsx" /* webpackChunkName: "component---src-pages-blog-categoria-[slug]-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-certificacoes-tsx": () => import("./../../../src/pages/certificacoes.tsx" /* webpackChunkName: "component---src-pages-certificacoes-tsx" */),
  "component---src-pages-contato-tsx": () => import("./../../../src/pages/contato.tsx" /* webpackChunkName: "component---src-pages-contato-tsx" */),
  "component---src-pages-corretor-tsx": () => import("./../../../src/pages/_corretor.tsx" /* webpackChunkName: "component---src-pages-corretor-tsx" */),
  "component---src-pages-empreendimento-tsx": () => import("./../../../src/pages/_empreendimento.tsx" /* webpackChunkName: "component---src-pages-empreendimento-tsx" */),
  "component---src-pages-empreendimentos-tsx": () => import("./../../../src/pages/_empreendimentos.tsx" /* webpackChunkName: "component---src-pages-empreendimentos-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/_index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-politica-de-privacidade-tsx": () => import("./../../../src/pages/politica-de-privacidade.tsx" /* webpackChunkName: "component---src-pages-politica-de-privacidade-tsx" */),
  "component---src-pages-sobre-tsx": () => import("./../../../src/pages/_sobre.tsx" /* webpackChunkName: "component---src-pages-sobre-tsx" */),
  "component---src-pages-sucesso-tsx": () => import("./../../../src/pages/sucesso.tsx" /* webpackChunkName: "component---src-pages-sucesso-tsx" */)
}

