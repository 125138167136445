import * as st from '~/assets/styl/HeroSectionAbout.module.styl'
import React from 'react'
import LogoHome from '~/assets/img/logo-aikon-sobre.png'

export const HeroSectionAbout = () => {
  return (
    <div className={st.core}>
      <section className={st.title}>
        <h1>
          <span className={st.firstSpan}>Conectando</span>
          <span className={st.firstSpan}>Você ao</span>
          <span className={st.firstSpan}>
            <strong>Novo tempo</strong>
          </span>
          <img src={LogoHome} alt="AIKON" width="220" height="67" />
        </h1>
      </section>
    </div>
  )
}

export default HeroSectionAbout
