import React, { useContext, useState } from 'react'
import { navigate } from 'gatsby'
import * as st from '~/assets/styl/SearchPost.module.styl'
import Link from './Link'
import { context } from '~/layouts'

interface SearchPostProps {
  categoriesName: (id: number) => string
  categories: { id: number; name: string; count: number }[]
}

const SearchPost = ({ categories }: SearchPostProps) => {
  const [tabItem, setTabItem] = useState('Search')
  const [searchTerm, setSearchTerm] = useState('')
  const { setCurrentPage } = useContext(context)

  const handleSearch = () => {
    if (searchTerm.trim()) {
      navigate(`/blog/busca/${encodeURIComponent(searchTerm)}`)
    }
  }

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleSearch()
    }
  }

  const handleCategoryClick = (categoryName: string) => {
    const slug = categoryName.toLowerCase().replace(/\s+/g, '-')
    navigate(`/blog/categoria/${slug}`)
  }

  const countCategories = categories.filter((c) => c.count > 0)

  return (
    <div className={st.core}>
      <div className={st.tab}>
        <div
          className={`${st.tabItem} ${
            tabItem === 'Search' ? st.active : st.inactive
          }`}
          onClick={() => setTabItem('Search')}
        >
          BUSCA
        </div>
        <div
          className={`${st.tabItem} ${
            tabItem === 'Category' ? st.active : st.inactive
          }`}
          onClick={() => setTabItem('Category')}
        >
          CATEGORIAS
        </div>
      </div>
      {tabItem === 'Search' ? (
        <div className={st.item}>
          <form
            onSubmit={(e) => {
              e.preventDefault()
              handleSearch()
            }}
            className={st.search}
          >
            <input
              className={st.searchInput}
              type="search"
              placeholder="O que você procura?"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              onKeyDown={handleKeyDown}
            />
            <button
              className={st.searchButton}
              title="Busca"
              type="button"
              onClick={handleSearch}
            >
              Buscar
            </button>
          </form>
        </div>
      ) : (
        <div className={st.item}>
          <div className={st.categories}>
            {countCategories.map((category) => (
              <Link
                key={category.id}
                href={`/blog/categoria/${category.name
                  .normalize('NFD')
                  .replace(/[\u0300-\u036f]/g, '')
                  .replace(/\s+/g, '-')
                  .toLowerCase()}`}
                style={{ cursor: 'pointer' }}
              >
                {category.name}
              </Link>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

export default SearchPost
