export function formatDate(date: string) {
  const newDate = new Date(date)
  const monthNames = [
    'jan',
    'fev',
    'mar',
    'abr',
    'mai',
    'jun',
    'jul',
    'ago',
    'set',
    'out',
    'nov',
    'dez',
  ]
  const day = newDate.getDate()
  const monthIndex = newDate.getMonth()
  const year = newDate.getFullYear()
  return `${day} de ${monthNames[monthIndex]}, ${year}`
}
