import * as st from '~/assets/styl/HomeSection.module.styl'
import React from 'react'
import LogoHome from '~/assets/img/Logo-white.png'

export const HeroSectionHome = () => {
  return (
    <div className={st.core}>
      <section className={st.title}>
        <h1>
          <img src={LogoHome} alt="AIKON" width="220" height="67" />
          <span className={st.firstSpan}>Conectando</span>
          <span>
            Você ao <strong>novo tempo</strong>
          </span>
        </h1>
      </section>
    </div>
  )
}

export default HeroSectionHome
