import React, { useEffect, useState } from 'react'
import * as st from '~/assets/styl/MostAccessed.module.styl'
import Slider from '@fil1pe/react-slider'
import { formatDate } from '~/utils/formatDate'
import Button from './Button'
import { useApiBlog as api } from '~/siteApi'

interface PinnedArticlesProps {
  posts: Posts[]
  categoriesName: (id: number) => string
}

const MostAccessed = ({ posts, categoriesName }: PinnedArticlesProps) => {
  const [tags, setTags] = useState<string[]>([])
  const [date, setDate] = useState<string>('')
  const [title, setTitle] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [slug, setSlug] = useState<string>('')
  const [image, setImage] = useState<string>('')
  const [fillPercentage, setFillPercentage] = useState(0)
  const [media, setMedia] = useState<{ [key: number]: string }>({})

  const fetchMedia = async () => {
    try {
      const mediaRequests = await Promise.all(
        posts.map(async (post) => {
          if (post.featured_media) {
            const response = await api.requestData(
              'get',
              `media/${post.featured_media}`
            )
            return {
              id: post.id,
              url:
                response.data.media_details.sizes['most-readed-thumbnail']
                  ?.source_url || null,
            }
          }
          return { id: post.id, url: null }
        })
      )

      const mediaData = mediaRequests.reduce((acc, { id, url }) => {
        acc[id] = url
        return acc
      }, {})

      setMedia(mediaData)
    } catch (error) {
      console.error('Erro ao buscar as mídias dos posts:', error)
    }
  }
  useEffect(() => {
    if (posts.length > 0) {
      const firstPost = posts[0]

      setTags(firstPost.categories.map((c) => categoriesName(c)))
      setDate(firstPost.date)
      setTitle(firstPost.title.rendered)
      setDescription(firstPost.excerpt.rendered)
      setImage(firstPost.yoast_head_json.og_image[0].url)
      setSlug(firstPost.slug)
    }
    const duration = 6000
    const intervalDuration = 100
    const increment = (intervalDuration / duration) * 100

    const interval = setInterval(() => {
      setFillPercentage((prev) => {
        if (prev + increment >= 100) {
          return 100
        }
        return prev + increment
      })
    }, intervalDuration)

    fetchMedia()
    return () => clearInterval(interval)
  }, [posts])

  return (
    <div className={st.core}>
      <Slider
        autoplayTimeout={500}
        className={st.slider}
        onSlideChange={(slide) => {
          setTitle(posts[slide].title.rendered)
          setDate(posts[slide].date)
          setDescription(posts[slide].excerpt.rendered)
          setSlug(posts[slide].slug)
          setTags(posts[slide].categories.map((c) => categoriesName(c)))
          setImage(posts[slide].yoast_head_json?.og_image[0]?.url)
          setFillPercentage(0)
        }}
      >
        {posts.map(({ id, title }) => (
          <div className={st.postFeatured} key={id}>
            <figure>
              <img src={media[id]} alt={title.rendered} />
            </figure>
          </div>
        ))}
      </Slider>
      <div className={st.post}>
        <h2 className={st.subtitle}>Mais acessados</h2>
        <div className={st.bar}>
          <span className={st.background}></span>
          <span
            className={st.fill}
            style={{
              width: `${fillPercentage}%`,
              transition: 'width 0.1s linear',
            }}
          ></span>
        </div>
        <section>
          <div className={st.tagAndDate}>
            <div className={st.tags}>
              {tags &&
                tags.map((tag: string, index: number) => (
                  <span key={index}>{tag}</span>
                ))}
            </div>
            <div className={st.date}>
              <span>{formatDate(date)}</span>
            </div>
          </div>
          <div className={st.mobile}>
            <figure>
              <img src={image} alt={title} />
            </figure>
          </div>
          <div className={st.text}>
            <h3 className={st.title}>{title}</h3>
            <p
              className={st.description}
              dangerouslySetInnerHTML={{ __html: description }}
            />
            <Button href={slug}>LER MAIS</Button>
          </div>
        </section>
      </div>
    </div>
  )
}

export default MostAccessed
