// extracted by mini-css-extract-plugin
export var active = "SearchPost-module--active--a1767";
export var categories = "SearchPost-module--categories--79dc3";
export var core = "SearchPost-module--core--710c1";
export var inactive = "SearchPost-module--inactive--7f426";
export var item = "SearchPost-module--item--1fa09";
export var search = "SearchPost-module--search--f8a46";
export var searchButton = "SearchPost-module--searchButton--dd49d";
export var searchInput = "SearchPost-module--searchInput--bc8c8";
export var tab = "SearchPost-module--tab--abe12";
export var tabItem = "SearchPost-module--tabItem--98160";