// extracted by mini-css-extract-plugin
export var arrow = "MosaicSection-module--arrow--a2f11";
export var concept = "MosaicSection-module--concept--9ddc8";
export var core = "MosaicSection-module--core--37c9e";
export var ctas = "MosaicSection-module--ctas--c5865";
export var first = "MosaicSection-module--first--715dd";
export var image = "MosaicSection-module--image--5d12d";
export var last = "MosaicSection-module--last--bb2b6";
export var outlined = "MosaicSection-module--outlined--eeea6";
export var prev = "MosaicSection-module--prev--d5fbd";
export var reverse = "MosaicSection-module--reverse--1bdc9";
export var slide = "MosaicSection-module--slide--1a157";
export var slider = "MosaicSection-module--slider--edf46";
export var text = "MosaicSection-module--text--af9a2";
export var triangle = "MosaicSection-module--triangle--14464";