import React, { useEffect, useState } from 'react'
import Slider from '@fil1pe/react-slider'
import * as st from '~/assets/styl/PinnedArticles.module.styl'
import Button from './Button'
import { useApiBlog as api } from '~/siteApi'

interface PinnedArticlesProps {
  posts: Posts[]
  categoriesName: (id: number) => string
}

const PinnedArticles = ({ posts, categoriesName }: PinnedArticlesProps) => {
  const pinnedPosts = posts.filter((c) => c.sticky)
  const [media, setMedia] = useState<{ [key: number]: string }>({})
  const fetchMedia = async () => {
    try {
      const mediaRequests = await Promise.all(
        pinnedPosts.map(async (post) => {
          if (post.featured_media) {
            const response = await api.requestData(
              'get',
              `media/${post.featured_media}`
            )
            return {
              id: post.id,
              url:
                response.data.media_details.sizes['sticky-thumbnail']
                  ?.source_url || null,
            }
          }
          return { id: post.id, url: null }
        })
      )

      const mediaData = mediaRequests.reduce((acc, { id, url }) => {
        acc[id] = url
        return acc
      }, {})

      setMedia(mediaData)
    } catch (error) {
      console.error('Erro ao buscar as mídias dos posts:', error)
    }
  }

  useEffect(() => {
    fetchMedia()
  }, [])
  return (
    <>
      {pinnedPosts.length > 0 && (
        <Slider className={st.core}>
          {pinnedPosts.map(({ excerpt, id, slug, title, categories }) => (
            <div className={st.postFeatured} key={id}>
              <div className={st.content}>
                <div className={st.tag}>
                  {categories &&
                    categories.map((categoryId: number, index: number) => (
                      <span key={index}>{categoriesName(categoryId)}</span>
                    ))}
                </div>
                <section>
                  <h2 className={st.title}>{title.rendered}</h2>
                  <p
                    className={st.description}
                    dangerouslySetInnerHTML={{ __html: excerpt.rendered }}
                  />
                  <Button className={st.link} href={slug}>
                    LER MAIS
                  </Button>
                </section>
              </div>
              <figure>
                <img src={media[id]} alt={title.rendered} />
              </figure>
            </div>
          ))}
        </Slider>
      )}
    </>
  )
}

export default PinnedArticles
